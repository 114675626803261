<template>
    <div>
        <layout-full-width :title="$t('inactive-groups')">
            <div class="row">
                <div class="col-2">
                    <div class="nav flex-column nav-pills" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                        <router-link class="nav-link" id="v-pills-home-tab" data-toggle="pill" href="#v-pills-home" role="tab" aria-controls="v-pills-home" aria-selected="true" to="/security/groups" exact active-class="active">
                            {{ $t('active-groups') }}
                        </router-link>
                        <router-link class="nav-link" id="v-pills-home-tab" data-toggle="pill" href="#v-pills-home" role="tab" aria-controls="v-pills-home" aria-selected="true" to="/security/groups/inactive-groups" exact active-class="active">
                            {{ $t('inactive-groups') }}
                        </router-link>
                    </div>
                </div>
            </div>

        </layout-full-width>
    </div>
</template>


<script>
import LayoutFullWidth from "@/layouts/LayoutFullWidth";

export default {
    name: "PageGroups",
    components: { LayoutFullWidth }
}
</script>

<style scoped>

.settings-sidebar {
    width: 220px;
    display: table-cell;
    padding-right: 30px;
    vertical-align: top;
}

ul {
    list-style-type: none;
}

nav.settings-sidebar ul li a {
    -webkit-transition: all 0.2s ease-out;
    -moz-transition: all 0.2s ease-out;
    -o-transition: all 0.2s ease-out;
    transition: all 0.2s ease-out;
    border: none;
    display: block;
    border-radius: 25px;
    padding: 7px 16px;
    text-decoration: none;
    color: #666666;
}

nav.settings-sidebar ul li a.active {
    background-color: #ffc200;
    color: #FFFFFF;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.1);
}
</style>